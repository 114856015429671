
import Vue, { PropType } from "vue";
import Component from "vue-class-component";
import { ChallengeTemplate } from "@/store/models/challengeTemplate";
import ChallengesTable from "@/components/editor/ChallengesTable.vue";
import { download } from "@/plugins/download";
import { Pack } from "@/store/models/pack";
import { PackTemplate } from "@/store/models/packTemplate";

@Component({
  computed: {
    packs(): PackTemplate[] {
      return this.$store.state.packs;
    },
  },
  components: {
    ChallengesTable,
  },
  mounted() {
    // @ts-ignore
    this.selectedPack = this.packs[0];
  },
})
export default class Overview extends Vue {
  selectedPack: PackTemplate | null = null;

  exportData(): void {
    download(
      `export-${Date.now()}.json`,
      JSON.stringify({ challenges: localStorage.challenges })
    );
  }
}
