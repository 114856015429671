
import Vue, { PropType } from "vue";
import Component from "vue-class-component";
import * as uuid from "uuid";
import {
  ChallengeCategory,
  ChallengeTemplate,
} from "../../store/models/challengeTemplate";
import { Pack } from "@/store/models/pack";
import { Challenge } from "@/store/models/challenge";
import { PackTemplate } from "@/store/models/packTemplate";

// Define the props by using Vue's canonical way.
const ChallengesTableProps = Vue.extend({
  props: {
    challenges: {
      type: Array as PropType<ChallengeTemplate[]>,
      required: true,
      validator(challenges) {
        return challenges.every((c) => c instanceof ChallengeTemplate);
      },
    },
  },
});

@Component({
  computed: {
    challengeCategories(): Array<{ id: ChallengeCategory; text: string }> {
      return [
        {
          id: "whoInTheGroup",
          text: this.$t("challenges.categories.whoInTheGroup.name") as string,
        },
        {
          id: "truthOrLie",
          text: this.$t("challenges.categories.truthOrLie.name") as string,
        },
        {
          id: "task",
          text: this.$t("challenges.categories.task.name") as string,
        },
        {
          id: "rule",
          text: this.$t("challenges.categories.rule.name") as string,
        },
        {
          id: "game",
          text: this.$t("challenges.categories.game.name") as string,
        },
        {
          id: "tournament",
          text: this.$t("challenges.categories.tournament.name") as string,
        },
        {
          id: "majorityWins",
          text: this.$t("challenges.categories.majorityWins.name") as string,
        },
        {
          id: "neverHaveIEver",
          text: this.$t("challenges.categories.neverHaveIEver.name") as string,
        },
      ];
    },
  },
})
export default class ChallengesTable extends ChallengesTableProps {
  // Initial data can be declared as instance properties
  headers = [
    // { text: "id", value: "id" },

    { text: "Category", value: "category" },
    {
      text: "Text",
      value: "primaryTextTemplate",
    },
    { text: "Language", value: "lang" },
    { text: "Author", value: "creator" },
    { text: "Actions", value: "actions", sortable: false },
  ];

  editedChallenge = new ChallengeTemplate(this.$store.creator);
  editedIndex = -1;
  dialog = false;
  dialogDelete = false;
  search = "";

  // Component methods can be declared as instance methods
  editItem(item: ChallengeTemplate) {
    this.editedIndex = this.challenges.findIndex((c) => c.id === item.id);
    this.editedChallenge = item;
    this.dialog = true;
  }

  deleteItem(item: ChallengeTemplate) {
    this.editedIndex = this.challenges.findIndex((c) => c.id === item.id);
    this.editedChallenge = item;
    this.dialogDelete = true;
  }

  deleteItemConfirm() {
    this.challenges.splice(this.editedIndex, 1);
    this.closeDelete();
    this.$store.save();
  }

  close() {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedChallenge = new ChallengeTemplate(this.$store.creator);
      this.editedIndex = -1;
    });
  }

  closeDelete() {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedChallenge = new ChallengeTemplate(this.$store.creator);
      this.editedIndex = -1;
    });
  }

  save() {
    if (this.editedIndex === -1) {
      this.challenges.push(this.editedChallenge);
    } else {
      this.challenges.splice(this.editedIndex, 1, this.editedChallenge);
    }
    this.close();
    this.$store.save();
  }
}
